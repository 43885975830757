import React, { Component } from "react";
import { connect } from "react-redux";
import DragIcon from "@mui/icons-material/DragIndicatorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Card, Dropdown as ActionBar, Modal } from "react-bootstrap";
import ReactDragListView from "react-drag-listview/lib/index.js";
import DataTable from "react-data-table-component";
import _ from "lodash";
import SelectProduct from "./SelectProduct";
import { uploadFile } from "../../services/fileUploadService";
import { checkImageRatio } from "../../components/ImageUploadError/ImageUploadError";

class SecondaryBannersTable extends Component {
  constructor(props) {
    super(props);

    const { banners = [], onBannersUpdated = (f) => f } = this.props;

    this.onBannersUpdated = onBannersUpdated;

    this.state = {
      banners,
      banner: {},
      errors: {},
      editables: {},
      loadError: "",
      sortColumn: "rank",
      sortDirection: "asc",
    };

    this.columns = [
      {
        name: "",
        cell: () => <DragIcon className="drag-handle"></DragIcon>,
        width: "50px",
      },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        maxWidth: "100px",
      },
      {
        name: "Image",
        selector: "image",
        maxWidth: "150px",
        cell: (point) => this.renderBannerImage(point),
      },
      {
        name: "Redirection Link",
        selector: "redirectionLink",
        sortable: true,
        wrap: true,
        minWidth: "180px"
      },
      {
        name: "Rank",
        selector: "rank",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (banner) => this.renderActionBar(banner),
      },
    ];

    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.banners !== this.state.banners) {
      this.setState({ banners: this.props.banners });
    }
  }

  clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (it) => (it.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (it) => (it.value = "")
    );
    this.setState({
      errors: {},
      editables: {},
      banner: {},
    });
  };

  handleImageFileSelect = async (event) => {
    if (event.target.files && event.target.files.length) {
      const imageFile = event.target.files[0];
      if (imageFile && imageFile.size && imageFile.size > 81920) {
        const errors = { ...this.state.errors };
        errors.bannerImage = "Image should be less than 80 Kb!";
        this.setState({ errors });
        return;
      }
      const imageRatioValid = await checkImageRatio(imageFile, 3, 1); // Corrected the ratio in the error message
      if (!imageRatioValid) {
        const errors = { ...this.state.errors };
        errors.bannerImage = "Image Ratio should be 3:1";
        this.setState({ errors });
        return;
      }
      else {
        const errors = { ...this.state.errors };
        if (errors.bannerImage) errors.bannerImage = "";
        this.setState({ errors });
      }

      try {
        this.showLoading();
        const { data: uploadDetails } = await uploadFile(imageFile);
        const banner = {
          ...this.state.banner,
          image: uploadDetails?.s3Url ?? "",
        };
        this.setState({ banner });
      } catch (error) {
        console.log(
          "handleImageFileSelect:: Error on uploading image file!",
          error
        );
        this.showErrorNotification(
          "Error on uploading image file! " + error.message ?? ""
        );
      } finally {
        this.hideLoading();
      }
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const banner = { ...this.state.banner };
    banner[input.name] = input.value;
    this.setState({ banner });
  };

  handleAddNew = () => {
    const banner = {};
    this.setState({ banner }, () => this.showAddEditModal());
  };

  handleEdit = (id) => {
    this.clearForm();
    const banners = this.state.banners ?? [];
    const banner = banners.find((it) => it.id === id);
    if (!banner) return;

    this.setState({ banner }, () => this.showAddEditModal());
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  handleDelete = (id) => this.deleteBanner(id);

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { banner = {} } = this.state;
    if (banner.id === null || banner.id === undefined) this.createBanner();
    else this.updateBanner(banner.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { image, redirectionLink } = this.state.banner ?? {};
    if (!image)
      errors.bannerImage = "Image is a required field!";
    
    if (!redirectionLink || !redirectionLink.trim() === "")
      errors.redirectionLink = "Redirection link is a required field!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createBanner = () => {
    const { banners = [], banner } = this.state;
    const ids = banners.length > 0 ? banners.map((it) => it.id) : null;
    banner.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
      banners.length > 0
        ? banners.filter((it) => it.rank).map((it) => it.rank)
        : null;
    banner.rank = ranks ? Math.max(...ranks) + 1 : 1;
    banner.isActive = true;
    banner.is_new = true;

    this.setState({ banners: [...banners, banner] }, () => {
      this.showSuccessNotification("Banner Created Successfully!");
      this.onBannersUpdatedLocally();
    });
  };

  updateBanner = (id) => {
    const { banners = [], banner } = this.state;
    const newBanners = banners.map((it) => (it.id === id ? banner : it));
    this.setState({ banners: newBanners }, () => {
      this.showSuccessNotification("Banner Updated Successfully!");
      this.onBannersUpdatedLocally();
    });
  };

  deleteBanner = (id) => {
    const { banners = [] } = this.state;
    const bannerToDelete = banners.find((it) => it.id === id) || {};
    if (!bannerToDelete) {
      console.log("deleteBanner:: Banner not found with id=", id);
      this.showErrorNotification("Banner not found!");
      return;
    }

    bannerToDelete.isActive = false;
    const newBanners = banners
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > bannerToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
    this.setState({ banners: newBanners }, () => {
      this.showSuccessNotification("Banner Deleted Successfully!");
      this.onBannersUpdatedLocally();
    });
  };

  updateRank = (fromIndex, toIndex) => {
    if (
      fromIndex === null ||
      fromIndex === undefined ||
      toIndex === null ||
      toIndex === undefined
    )
      return;

    const { banners = [], sortColumn, sortDirection } = this.state;
    const sortedBanners = _.sortBy(banners, sortColumn, sortDirection);
    const fromBanner = { ...sortedBanners[fromIndex] };
    const toBanner = { ...sortedBanners[toIndex] };

    if (fromBanner && toBanner) {
      const tempRank = fromBanner.rank;
      fromBanner.rank = toBanner.rank;
      toBanner.rank = tempRank;

      const newBanners = banners.map((it) => {
        if (it.id === fromBanner.id) return fromBanner;
        else if (it.id === toBanner.id) return toBanner;
        else return it;
      });
      this.setState({ banners: newBanners }, () => {
        this.showSuccessNotification("Rank Updated Successfully!");
        this.onBannersUpdatedLocally();
      });
    } else {
      this.showErrorNotification("Rank Updation Failed!");
    }
  };

  onBannersUpdatedLocally = () => {
    this.clearForm();
    this.onBannersUpdated(
      this.state.banners.map((banner) => {
        return { ...banner };
      })
    );
  };

  renderBannerImage = (banner) => (
    <img
      src={banner.image}
      className="form-image"
      alt="Points Icon"
      // width={100}
      height={50}
    />
  );

  renderActionBar = (banner) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu
        popperConfig={{
          strategy: "fixed",
          onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll")),
        }}
      >
        <ActionBar.Item onClick={() => this.handleEdit(banner.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item
          onClick={() =>
            this.setState({ bannerToDelete: banner.id }, () =>
              this.showConfirmDeleteModal()
            )
          }
        >
          <DeleteIcon className="icon-small" style={{ fill: "red" }} />
          <span style={{ color: "red" }}>Delete</span>
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };
    return this.state.banner.id ? (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-text"
      >
        <EditIcon />
      </div>
    ) : (
      ""
    );
  };

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      banners = [],
      banner = {},
      editables,
      errors,
      sortColumn,
    } = this.state;
    const isNewBanner = !banner.id;

    const form = (
      <>
        <div className="row mt-2">
          <div className="form-group col-6">
            <label htmlFor="bannerImage">Image*</label>
            <div className="input-group">
              <input
                type="file"
                accept="image/*"
                onChange={this.handleImageFileSelect}
                name="bannerImage"
                id="bannerImage"
                className="form-control"
                disabled={!isNewBanner && !editables.bannerImage}
              />
              {this.renderPencilIcon("bannerImage")}
            </div>
            <span className="help-block">{errors.bannerImage}</span>
          </div>
          {banner?.image && (
            <div className="form-group col-6">
              <label htmlFor="selectedBannerImage">Selected Image</label>
              <div>
                <img
                  id="selectedBannerImage"
                  name="selectedBannerImage"
                  src={banner.image}
                  alt="Selected"
                  className="img-thumbnail mx-auto"
                  style={{
                    width: "100%",
                    maxHeight: "200px",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="row mt-2">
          <div className="form-group col">
            <label htmlFor="redirectionLink">Redirection Link*</label>
            <div className="input-group">
              <input
                type="text"
                value={banner?.redirectionLink ?? ""}
                onChange={(event) => {
                  this.setState({
                    banner: {
                      ...banner,
                      redirectionLink: event.target.value,
                    },
                  });
                }}
                placeholder="Redirection Link"
                id="redirectionLink"
                name="redirectionLink"
                className="form-control"
                disabled={!isNewBanner && !editables.redirectionLink}
              />
              {this.renderPencilIcon("redirectionLink")}
            </div>
            <span className="help-block">{errors.redirectionLink}</span>
            <br></br>
            <span className="" style={{color:"green"}}>  <ul>
                <li>Image Ratio should be 3:1</li>
                <li>Recommended 1080 x 360 Resolution</li>
                <li>size upto 80kb</li>
              </ul></span>
          </div>
        </div>
      </>
    );

    return (
      <div className="container px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <ReactDragListView {...this.dragProps}>
                <DataTable
                  highlightOnHover
                  columns={this.columns}
                  data={banners ?? []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  // sortIcon={<ArrowDownward></ArrowDownward>}
                />
              </ReactDragListView>
              <button
              style={{
                cursor:(this.state.banners.length>5 ? "not-allowed" : "pointer")
              }}
                onClick={this.handleAddNew}
                className="greenbackground"
                disabled={this.state.banners.length > 5}
              >
                Add New
               
              </button>
            </Card>
          </div>
        </div>
        <Modal
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {banner.id === null || banner.id === undefined
                ? "Add Banner"
                : "Edit Banner"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              type="button" style={{borderRadius:"20px"}}
              onClick={this.hideAddEditModal}
              className="btn btn-sm btn-outline-secondary px-4 py-2"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={this.handleSubmit}
              className="greenbackground"
            >
              {banner.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Banner</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteBanner(this.state.bannerToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecondaryBannersTable);
