import React, { useEffect, useState, useCallback } from "react";
import { Card } from 'react-bootstrap';
import { BodyTextEditor } from "../../../../components/RichEdittorComp";
import './product-details.scss';
export default function ProductDetails({productDetails,setProductDetails,productDetailsError={},productSeoUrl="",setProductSeoUrl="",seoUrlError="" , bestSellerTag ,setBestSellerTag}) {

  const onProductDetailsChange = ({fieldName, value}) => {
    // const sanitizedValue = value.replace(/<\/?[^>]+(>|$)/g, '');
    let productData = {
      [fieldName] : value
    }
    console.log("start", fieldName, value)
    setProductDetails({...productDetails,...productData})
    console.log("productDetails",productDetails)
  }

  const {title="",description="",ingrediants="",benefits="",id="",shortDescription="",titleArabic="",shortDescriptionArabic="",descriptionArabic="",ingrediantsArabic="",benefitsArabic="" } = productDetails || {};

  console.log("bestSellerTag",bestSellerTag)
  return (
    <div className="product-details-wrapper">
          <Card body>
            <div>
              <div className="section-title">Product Details</div>
              <div className="alignrow section-gap">
              <div className="section-gap">
              <label className="label-txt">Title (English)*</label>
                <input  id="title" type="text" value={title || ""} className="inputfieldsize" required onChange={(e)=>onProductDetailsChange({fieldName:"title",value:e.target.value})} />
               {productDetailsError.title && <span style={{fontSize:'12px',color:'red'}} >{productDetailsError.title}</span>}
               {<span style={{fontSize:'14px',color:'green',fontWeight:"600"}} >Title must be less than 180 characters</span>}
              </div>
              <div className="section-gap">
              <label className="label-txt">Title (Arabic)*</label>
                <input  id="title" type="text" value={titleArabic || ""} className="ar-inputfieldsize" required onChange={(e)=>onProductDetailsChange({fieldName:"titleArabic",value:e.target.value})} />
               {productDetailsError.titleArabic && <span style={{fontSize:'12px',color:'red'}} >{productDetailsError.titleArabic}</span>}
              </div>
              </div>
              <div className="alignrow section-gap">
              <div className="section-gap">
                <label className="label-txt"> Short Description</label>
                <input  id="shortDescription" type="text" value={shortDescription || ""} className="inputfieldsize" required onChange={(e)=>onProductDetailsChange({fieldName:"shortDescription",value:e.target.value})} />
                {productDetailsError.shortDescription && <span style={{fontSize:'12px',color:'red'}} >{productDetailsError.shortDescription}</span>}
              </div>
              <div className="section-gap">
                <label className="label-txt"> Short Description (Arabic)</label>
                <input  id="shortDescription" type="text" value={shortDescriptionArabic || ""} className="ar-inputfieldsize" required onChange={(e)=>onProductDetailsChange({fieldName:"shortDescriptionArabic",value:e.target.value})} />
                {productDetailsError.shortDescriptionArabic && <span style={{fontSize:'12px',color:'red'}} >{productDetailsError.shortDescriptionArabic}</span>}
              </div>
              </div>
             
              <div className="alignrow section-gap">
                <div className="productDescription" style={{display:"flex",gap:"10px",width:"50%"}}>
              <div className="section-gap">
                <label className="label-txt">Description</label>
                <BodyTextEditor 
                  required
                  fieldName={"description"}
                  value={description || ""}
                  setValue={bodyText =>onProductDetailsChange({fieldName:"description",value:bodyText})}
                /> 
                {productDetailsError.description && <span style={{fontSize:'12px',color:'red'}} >{productDetailsError.description}</span>}
              </div>
              <div className="section-gap">
                <label className="label-txt">Description (Arabic)</label>
                <BodyTextEditor 
                  required
                  fieldName={"descriptionArabic"}
                  value={descriptionArabic || ""}
                  setValue={bodyText =>onProductDetailsChange({fieldName:"descriptionArabic",value:bodyText})}
                /> 
                {productDetailsError.descriptionArabic && <span style={{fontSize:'12px',color:'red'}} >{productDetailsError.descriptionArabic}</span>}
              </div>
              </div>
              </div>
             
              <div className="alignrow section-gap">
                <div> 
                  <label className="label-txt">Ingredients &amp; Dosage</label>
                  <BodyTextEditor 
                    fieldName={"ingrediants"}
                    value={ingrediants || ""}  setValue={bodyText =>onProductDetailsChange({fieldName:"ingrediants",value:bodyText})}/>
                  </div>
                  <div> 
                  <label className="label-txt">Ingredients &amp; Dosage (Arabic)</label>
                  <BodyTextEditor 
                    fieldName={"ingrediantsArabic"}
                    value={ingrediantsArabic || ""}  setValue={bodyText =>onProductDetailsChange({fieldName:"ingrediantsArabic",value:bodyText})}/>
                  </div>
                  </div>
                  <div className="alignrow section-gap">
                <div> 
                  <label className="label-txt">Benefits</label> 
                  <BodyTextEditor 
                   fieldName={"benefits"}
                  value={benefits || ""} setValue={bodyText =>onProductDetailsChange({fieldName:"benefits",value:bodyText})}/>
                  </div>
                  <div> 
                  <label className="label-txt">Benefits (Arabic)</label> 
                  <BodyTextEditor 
                   fieldName={"benefitsArabic"}
                  value={benefitsArabic || ""} setValue={bodyText =>onProductDetailsChange({fieldName:"benefitsArabic",value:bodyText})}/>
                  </div>
              </div>
              <br/>
              <div className="columnalignactive">
                                            <div className="group">
                                                <label for="name" className="label-title-txt">Seo Url *</label>  
                                                <input type="text"  value={productSeoUrl || ""} onChange={(e)=>setProductSeoUrl(e.target.value)} />
                                                {seoUrlError && <span style={{fontSize:'12px',color:'red'}} >{seoUrlError}</span>}
                                            </div>
                                        </div>
                                        <div className="bestSeller">
                                <div className="">Best Seller Tag</div>
                                <input type="checkbox" checked={bestSellerTag} class="checkbox-round" onChange={(e)=>setBestSellerTag(e.target.checked)} />
                            </div> 
              
            </div>
          </Card>
    </div>
  )
}
