import React, { Component } from "react";
import { connect } from "react-redux";
import DragIcon from "@mui/icons-material/DragIndicatorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Card, Dropdown as ActionBar, Modal } from "react-bootstrap";
import ReactDragListView from "react-drag-listview/lib/index.js";
import DataTable from "react-data-table-component";
import _ from "lodash";
import SelectProduct from "./SelectProduct";
import API from "../../utils/API";
import SelectProductType from "./SelectProductType";
import { uploadFile } from "../../services/fileUploadService"

class MenuTableHealthGoals extends Component {
  constructor(props) {
    super(props);

    const { healthGoals = [], onHealthGoalsUpdated = (f) => f } = props;
    this.onHealthGoalsUpdated = onHealthGoalsUpdated;

    this.state = {
      healthGoals,
      healthGoal: {},
      errors: {},
      editables: {},
      loadError: "",
      sortColumn: "rank",
      sortDirection: "asc",
    };

    this.columns = [
      {
        name: "",
        cell: () => <DragIcon className="drag-handle"></DragIcon>,
        width: "50px",
      },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        maxWidth: "100px",
      },
      {
        name: "Image",
        selector: "icon",
        maxWidth: "150px",
        cell: (row) => this.renderIcon(row),
      },
      {
        name: "Health Goal",
        selector: "healthGoal",
        sortable: true,
        wrap: true,
      },
      {
        name: "Rank",
        selector: "rank",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (row) => this.renderActionBar(row),
      },
    ];

    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.healthGoals !== this.state.healthGoals) {
      this.setState({ healthGoals: this.props.healthGoals });
    }
  }

  clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (it) => (it.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (it) => (it.value = "")
    );
    this.setState({
      errors: {},
      editables: {},
      healthGoal: {},
    });
  };

  handleImageFileSelect = async (event) => {
    if (event.target.files && event.target.files.length) {
      const imageFile = event.target.files[0];
      if (imageFile && imageFile.size && imageFile.size > 204800) {
        const errors = { ...this.state.errros };
        errors.HealthGoalIcon = "Icon should be less than 200 Kb!";
        this.setState({ errors });
        return;
      } else {
        const errors = { ...this.state.errros };
        if (errors.HealthGoalIcon === "Icon should be less than 200 Kb!")
          errors.HealthGoalIcon = "";
        this.setState({ errors });
      }

      try {
        this.showLoading();
        const { data: uploadDetails } = await uploadFile(imageFile);
        const healthGoal = {
          ...this.state.healthGoal,
          icon: uploadDetails?.s3Url ?? "",
        };
        this.setState({ healthGoal });
      } catch (error) {
        console.log(
          "handleImageFileSelect:: Error on uploading image file!",
          error
        );
        this.showErrorNotification(
          "Error on uploading image file! " + error.message ?? ""
        );
      } finally {
        this.hideLoading();
      }
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const healthGoal = { ...this.state.healthGoal };
    healthGoal[input.name] = input.value;
    this.setState({ healthGoal });
  };

  handleAddNew = () => {
    const healthGoal = {};
    this.setState({ healthGoal }, () => this.showAddEditModal());
  };

  handleEdit = (id) => {
    this.clearForm();
    const healthGoals = this.state.healthGoals ?? [];
    const healthGoal = healthGoals.find((it) => it.id === id);
    if (!healthGoal) return;

    this.setState({ healthGoal }, () => this.showAddEditModal());
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  handleDelete = (id) => this.deleteHealthGoal(id);

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { healthGoal = {} } = this.state;
    if (healthGoal.id === null || healthGoal.id === undefined)
      this.createHealthGoal();
    else this.updateHealthGoal(healthGoal.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { healthGoalId, icon } = this.state.healthGoal ?? {};
    if (!healthGoalId) errors.healthGoalId = "Health goal is a required field!";

    if (!icon)
    errors.HealthGoalIcon = "Icon is a required field!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createHealthGoal = () => {
    const { healthGoals = [], healthGoal } = this.state;
    const ids = healthGoals.length > 0 ? healthGoals.map((it) => it.id) : null;
    healthGoal.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
      healthGoals.length > 0
        ? healthGoals.filter((it) => it.rank).map((it) => it.rank)
        : null;
    healthGoal.rank = ranks ? Math.max(...ranks) + 1 : 1;
    healthGoal.isActive = true;
    healthGoal.is_new = true;

    this.setState({ healthGoals: [...healthGoals, healthGoal] }, () => {
      this.showSuccessNotification("Health Goal Created Successfully!");
      this.onHealthGoalsUpdatedLocally();
    });
  };

  updateHealthGoal = (id) => {
    const { healthGoals = [], healthGoal } = this.state;
    const updatedHealthGoals = healthGoals.map((it) =>
      it.id === id ? healthGoal : it
    );
    this.setState({ healthGoals: updatedHealthGoals }, () => {
      this.showSuccessNotification("Health Goal Updated Successfully!");
      this.onHealthGoalsUpdatedLocally();
    });
  };

  deleteHealthGoal = (id) => {
    const { healthGoals = [] } = this.state;
    const healthGoalToDelete = healthGoals.find((it) => it.id === id) || {};
    if (!healthGoalToDelete) {
      console.log("deleteHealthGoal:: Health Goal not found with id=", id);
      this.showErrorNotification("Health Goal not found!");
      return;
    }

    healthGoalToDelete.isActive = false;
    const updatedHealthGoals = healthGoals
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > healthGoalToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
    this.setState({ healthGoals: updatedHealthGoals }, () => {
      this.showSuccessNotification("Health Goal Deleted Successfully!");
      this.onHealthGoalsUpdatedLocally();
    });
  };

  updateRank = (fromIndex, toIndex) => {
    if (
      fromIndex === null ||
      fromIndex === undefined ||
      toIndex === null ||
      toIndex === undefined
    )
      return;

    const { healthGoals = [], sortColumn, sortDirection } = this.state;
    const sortedHealthGoals = _.sortBy(healthGoals, sortColumn, sortDirection);
    const fromHealthGoal = { ...sortedHealthGoals[fromIndex] };
    const toHealthGoal = { ...sortedHealthGoals[toIndex] };

    if (fromHealthGoal && toHealthGoal) {
      const tempRank = fromHealthGoal.rank;
      fromHealthGoal.rank = toHealthGoal.rank;
      toHealthGoal.rank = tempRank;

      const updatedHealthGoals = healthGoals.map((it) => {
        if (it.id === fromHealthGoal.id) return fromHealthGoal;
        else if (it.id === toHealthGoal.id) return toHealthGoal;
        else return it;
      });
      this.setState({ healthGoals: updatedHealthGoals }, () => {
        this.showSuccessNotification("Rank Updated Successfully!");
        this.onHealthGoalsUpdatedLocally();
      });
    } else {
      this.showErrorNotification("Rank Updation Failed!");
    }
  };

  onHealthGoalsUpdatedLocally = () => {
    this.clearForm();
    this.onHealthGoalsUpdated(
      this.state.healthGoals.map((item) => ({ ...item }))
    );
  };

  findProductTypeById = (id) => {
    const productTypes = this.props.productTypesAll ?? [];
    return productTypes.find((it) => it.id === id);
  };

  renderIcon = (row) => (
    <img
      src={row.icon}
      className="form-image"
      alt="Icon"
      height={50}
    />
  );

  renderActionBar = (healthGoal) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu
        popperConfig={{
          strategy: "fixed",
          onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll")),
        }}
      >
        <ActionBar.Item onClick={() => this.handleEdit(healthGoal.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item
          onClick={() =>
            this.setState({ healthGoalToDelete: healthGoal.id }, () =>
              this.showConfirmDeleteModal()
            )
          }
        >
          <DeleteIcon className="icon-small" style={{ fill: "red" }} />
          <span style={{ color: "red" }}>Delete</span>
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };
    return this.state.healthGoal.id ? (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-text"
      >
        <EditIcon />
      </div>
    ) : (
      ""
    );
  };

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });
  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      healthGoals = [],
      healthGoal = {},
      editables,
      errors,
      sortColumn,
    } = this.state;
    const isNewHealthGoal = !healthGoal.id;

    healthGoals.forEach((it) => {
      it.healthGoal =
        this.findProductTypeById(it.healthGoalId)?.description?.name ?? "";
    });

    const form = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="healthGoalId">Health Goal*</label>
            <div className="input-group">
              <SelectProductType
                id="healthGoalId"
                productTypes={this.props.productTypes ?? []}
                value={healthGoal.healthGoalId}
                onChange={(value) =>
                  this.setState({
                    healthGoal: {
                      ...healthGoal,
                      healthGoalId: value.value,
                    },
                  })
                }
                className={
                  "form-control" + (errors.healthGoalId ? " has-error" : "")
                }
                isDisabled={!isNewHealthGoal && !editables.healthGoalId}
              />
              {this.renderPencilIcon("healthGoalId")}
            </div>
            <span className="help-block">{errors.healthGoalId}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="form-group col-6">
            <label htmlFor="categoryIcon">Image*</label>
            <div className="input-group">
              <input
                type="file"
                accept="image/*"
                onChange={this.handleImageFileSelect}
                name="categoryIcon"
                id="categoryIcon"
                className="form-control"
                // disabled={!isNewCategory && !editables.categoryIcon}
              />
              {this.renderPencilIcon("categoryIcon")}
            </div>
            <span className="help-block">{errors.HealthGoalIcon}</span>
            <br></br>
            <span className="" style={{color:"green"}}> Image Ratio should be 1:1 & Recommended 1080 x 1080 Resolution</span>
          </div>
          {healthGoal?.icon && (
            <div className="form-group col-6">
              <label htmlFor="selectedCategoryIcon">Selected Image</label>
              <div>
                <img
                  id="selectedCategoryIcon"
                  name="selectedCategoryIcon"
                  src={healthGoal.icon}
                  alt="Selected Category Icon"
                  className="img-thumbnail mx-auto"
                  style={{
                    width: "100%",
                    maxHeight: "200px",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </>
    );

    return (
      <div className="container px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <p className="m-2 h6">Explore Category</p>
              <ReactDragListView {...this.dragProps}>
                <DataTable
                  highlightOnHover
                  columns={this.columns}
                  data={healthGoals ?? []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  // sortIcon={<ArrowDownward></ArrowDownward>}
                />
              </ReactDragListView>
              <button
                onClick={this.handleAddNew}
                className="greenbackground"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        
        <Modal
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {healthGoal.id === null || healthGoal.id === undefined
                ? "Add Health Goal"
                : "Edit Health Goal"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              type="button" style={{borderRadius:"20px"}}
              onClick={this.hideAddEditModal}
              className="btn btn-sm btn-outline-secondary px-4 py-2"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={this.handleSubmit}
              className="greenbackground"
            >
              {healthGoal.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Health Goal</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteHealthGoal(this.state.healthGoalToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuTableHealthGoals);
