import React, { useEffect, useState, useCallback } from "react";
import { Card } from 'react-bootstrap';
import { BodyTextEditorCreate } from "../../../../components/RichEditorCreateComp";
import { BodyTextEditor } from "../../../../components/RichEdittorComp";
import EditIcon from "../../../../assets/img/edit.png";
import DeleteIcon from "../../../../assets/img/delete.png";
import API from "../../../../utils/API";
import Spinner from "../../../../components/Spinner/Spinner";
import './media.scss';
import { CheckBox } from "@mui/icons-material";
import { checkImageRatio } from "../../../../components/ImageUploadError/ImageUploadError";



export default function Media({mediaDetails,setMediaDetails,mediaDetailsError}) {

    const [isLoading , setIsLoading] = useState(false);
    const [imageUploadError,setImageUploadError] = useState("")



    const handleImages = async(event) => {
        if (event.target.files && event.target.files.length) {
          setIsLoading(true)
          console.log("all images",event.target.files);
          const imageFiles = event.target.files;

          console.log("imageFileimageFile",imageFiles)
          let hasError = false;

          for (let i = 0; i < imageFiles.length; i++) {
            const imageFile = imageFiles[i];
            // Check if the file size exceeds 100KB (100 * 1024 bytes)
            if (imageFile.size > 102400) {
              setImageUploadError("Image should be less than 100 KB!");
              hasError = true;
              setIsLoading(false);
              break;
            }

            const imageRatioValid = await checkImageRatio(imageFile,1,1);
            if (!imageRatioValid) {
              setImageUploadError("Image should have a 1:1 aspect ratio!");
              hasError = true;
              setIsLoading(false);
              break;
            }
          }
      
          if (hasError) {
            return;
          }
          try {
            uploadFileAll(imageFiles);
            }
           catch (error) {
            console.log(
              "handleImageFileSelect:: Error on uploading image file!",
              error
            );
            // showErrorNotification(
            //   "Error on uploading image file! " + error.message ?? ""
            // );
          } finally {
            // hideLoading();
          }
        }
      };
      console.log("imageUploadError",imageUploadError)

      const  uploadFileAll =(images)=>
      {
      const promises = [];
      console.log("length" ,images.length);
      for(let i=0;i<images.length;i++)
       {
        const formData = new FormData();
          formData.append("file", images[i]);
          const config = {
            method: "POST",
            url: "/s3/file/upload",
            data: formData,
            headers: {
              accept: "application/json",
              "Accept-Language": "en-US,en;q=0.8",
              "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
            },
          };
        promises.push(API.request(config));
      }
      
      // Once all promises are resolved, update the state
      Promise.all(promises).then((responses) =>  { 
        
        const newImages = responses.map((response) => ({
          image: response.data.s3Url,
          isDefault: false, 
        }));
        setMediaDetails([...mediaDetails,...newImages]);
        setIsLoading(false)
      });
      
      
      }

    
      const onRemoveImage = (index) =>{
        const filterItem = mediaDetails.filter((_, i) => i !== index);
        setMediaDetails(filterItem)
       
      }
      const toggleCheckbox = (index) => {
        const updatedMediaDetails = [...mediaDetails];
        updatedMediaDetails.forEach((item, i) => {
          item.isDefault = i === index;
        });
        setMediaDetails(updatedMediaDetails);
      };
      console.log("mediaDatamediaDatamediaDetailsError",mediaDetailsError)
      console.log("bshbh",mediaDetails)
  return (
    <div className="media-wrapper">
          <Card body>
            <div className="media-container">
                <label className="label-txt">Media*</label>
                <div className="alignrowcenter"> 
                    <label className="label">
                        <div className="action-btn">+ Upload Image</div> 
                        <input type="file" className="variantbutton" style={{display:'none'}} accept="image/*" multiple onChange={handleImages}/>
                    </label>
                    {mediaDetailsError.media && <span style={{fontSize:'12px',color:'red'}} >{mediaDetailsError.media}</span>}
                </div>
                
            </div>
            <div className="" style={{color:"green", fontWeight:"600"}}> <ul>
                <li>Image Ratio should be 1:1</li>
                <li>Recommended 512 x 512 Resolution</li>
                <li>size upto 100kb</li>
              </ul></div>
              { imageUploadError && <span style={{fontSize:'12px',color:'red'}} >{imageUploadError}</span> }
            {/* list of images */}
            <div className="image-wrapper">
                {
                    mediaDetails.map((data,index)=>{
                        return(
                            <div className="image-card-container">
                            <div className="checkBox">
                            <input type="checkbox" checked={data?.isDefault}
                            onChange={() => toggleCheckbox(index)} />
                            </div>
                            <div className="img-container">
                                <img src={data?.image} alt=""/>
                            </div>
                            <div className="img-action-container" onClick={()=>onRemoveImage(index)}>
                                {/* <img src={EditIcon} alt="edit-icon"/> */}
                                <img src={DeleteIcon} alt="delete-icon"/>
                            </div>
                        </div>
                        )
                    })
                }
               
            </div>
            <Spinner isShow={isLoading} />
          </Card>
    </div>
  )
}
